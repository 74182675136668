<template>
  <div class="b2c-page-bg pb-4">
<!--    <RegisterStep :currentStep="4" ></RegisterStep>-->
    <div class="register-form px-4 pb-2 " >
      <div class="survey-order w-100 text-center">
        <img src="@/assets/images/finish-fail.svg" class="mb-4">
        <h3 v-if="isUpdateInfo">Cập nhật thông tin thuê bao không thành công</h3>
        <h3 v-else>Đăng ký thông tin thuê bao không thành công</h3>
        <h4 class="mb-2 text-12 mt-2 text-center" v-if="isUpdateInfo">
          Gửi yêu cầu thất bại<br/>Rất tiếc, kết nối hiện đang bị gián đoạn.<br/>
          Mong Bạn thông cảm và vui lòng đợi chút, bộ phận CSKH sẽ liên hệ và hỗ trợ Bạn trong 5 phút tới.<br/>iTel rất
          xin lỗi vì sự bất tiện này!
        </h4>
        <h4 class="mb-2 text-12 mt-2 text-center px-2" v-else>
          Gửi yêu cầu thất bại<br/>Rất tiếc, kết nối hiện đang bị gián đoạn.<br/>
          Mong Bạn thông cảm và vui lòng đợi chút, bộ phận CSKH sẽ liên hệ và hỗ trợ Bạn trong 5 phút tới.<br/>iTel rất
          xin lỗi vì sự bất tiện này!
        </h4>
        <div class="survey-order-content">
          <div class="p-3 mb-4" style="background: rgba(242, 242, 242, 1);border-radius: 10px;">
            <div class="w-100 d-flex text-left ">
              <div class="w-50">Số thuê bao:</div>
              <div class="w-50 font-weight-bolder">0879999735</div>
            </div>
            <div class="w-100 d-flex text-left mt-2" v-if="!isUpdateInfo">
              <div class="w-50">Thời gian kích hoạt:</div>
              <div class="w-50 font-weight-bolder">-</div>
            </div>
          </div>
          <div class="row pt-1">
            <div class="col-6">
              <b-button
                  class="style-button-back"
                  @click="backToHome"
              >Quay về trang chủ
              </b-button>
            </div>
            <div class="col-6">
              <b-button
                  class="style-button-contact"
                  @click="$bvModal.show('modal-phone')"
              >
                <span class="font-weight-btn"> Liên hệ CSKH</span> <br/>
                087 7087087
              </b-button>
            </div>
            <b-modal
                hide-header
                body-class="p-2"
                title-class="text-primary"
                hide-footer
                id="modal-phone"
                content-class="your-class"
            >
              <b-button class="mt-1 d-flex align-items-center style-btn" block>
                <a href=" tel:087 7087087">
            <span class="mr-2">
              <img
                  loading="lazy"
                  src="@/assets/images/contact-phone.png"
                  alt="Hotline ITEL"
              />
            </span>
                  <span class="text-contact"> Gọi 087 7087087</span>
                </a>
              </b-button>
              <b-button
                  class="mt-2 style-btn text-contact"
                  block
                  @click="$bvModal.hide('modal-phone')"
              >Hủy
              </b-button
              >
            </b-modal>
            <div/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useJwt from "@/auth/jwt/useJwt";
import {BFormInput, BButton, BFormRating} from "bootstrap-vue";
import RegisterStep
  from "@/components/RegisterStep.vue";

export default {
  components: {
    BButton,
    BFormInput,
    BFormRating,
    RegisterStep
  },
  data() {
    return {
      contribute: "",
      params: {
        phone: "",
        sourceType: "",
        productType: "",
        orderId: "",
        score: null,
        content: "",
      },
      isUpdateInfo: false,
    };
  },
  mounted() {
    console.log("mounted", this.$route.params.slug)
    if (this.$route.params.slug === 'cap-nhat') {
      this.isUpdateInfo = true;
    }
    window.hideLayout(true)
  },
  methods: {
    backToHome() {
      this.$router.push({name: "home"});
    },
  }

};
</script>
<style lang="scss" scoped>
.b2c-page-bg {
  background: rgba(242, 242, 242, 1);
}

.register-form {
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
  font-size: medium;
  font-family: 'roboto';
  font-weight: 400;
  background-color: white;
  border-radius: 15px;

}

.registerStep {
  max-width: 122.4rem;
  margin-left: auto;
  margin-right: auto;
  font-size: medium;
  font-family: 'roboto';
  font-weight: 400;

}

h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  color: #5C5C5C;
}

.survey-order-content-txt {
  justify-content: space-between;
  margin: 0 7%;
  display: flex;
  padding-bottom: 30px;
}

.survey-order-content-rating {
  margin: 0 7%;;
}

.style-button-contact {
  width: 100%;
  border-radius: 12px !important;
  font-size: 16px;
  font-weight: 500;
  color: #ED1F24 !important;
  background-color: white !important;
  border-color: #ED1F24 !important;
}

.font-weight-btn {
  font-weight: 300;
}

.style-button-back {
  width: 100%;
  border-radius: 12px !important;
  font-size: 16px;
  font-weight: 500;
  background-color: #ED1F24 !important;
  border-color: #ED1F24 !important;
}

.style-button {
  width: 100%;
  border-radius: 27px !important;
  font-size: 18px;
  font-weight: 600;
}

.text-14 {
  font-size: 14px;
}

.survey-order {
  font-size: 16px;
  padding-top: 28px;
  //   padding-bottom: 21px;
}

.style-btn {
  background: #fff !important;
}

.text-contact {
  color: #007bff !important;
  font-size: 18px;
}

@media screen and (max-width: 460px) {
  .survey-order-content-rating,
  .survey-order-content-txt {
    margin: 0;
  }
}

@media screen and (max-width: 400px) {
  .style-button-contact {
    font-size: 14px;
  }
  .style-button-back {
    font-size: 14px;
  }
}
</style>